import classNames from 'classnames';
import Image from 'components/image';
import {Link} from 'gatsby';
import React from 'react';
import Style from 'styles/components/comachicart/topnews.module.scss';

import {useNews} from './News';

const TopNewsPresenter = ({topNews}) => {
  return topNews ?
      (<div className = {classNames(Style.news)}>
       <Image className = {classNames(Style.news_label)} filename =
        {
          'comachi_lp/top/news.png'
        } />
      <div>
          <Link className={classNames(Style.news_title)} to={topNews.link}>
            {topNews.title}
          </Link>
      </div>
       </div>
  ) : (
    <></>);
};

const TopNews = () => {
  const topNews = useNews()[0];
  return <TopNewsPresenter topNews={topNews} />;
};

export default TopNews;
