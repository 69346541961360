import classNames from "classnames";
import { compareDesc, format, isBefore, isEqual, parse } from "date-fns";
import {Link} from 'gatsby';
import React, { useEffect, useState } from "react";
import Style from "styles/components/comachicart/owned_media.module.scss";
import { owned_media_list } from "../../constants";

const fetchMedia = async () => {
  try {
    const response = await fetch(owned_media_list, { cache: "no-store" });
    if (!response.ok) {
      throw new Error("メディアデータの取得に失敗しました");
    }
    return await response.json();
  } catch (error) {
    console.error("fetchMedia エラー:", error);
    return [];
  }
};

export const useMedia = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const loadMedia = async () => {
      const json = await fetchMedia();
      if (!json) return setItems([]);

      let items = json;
      setItems(json);
    };

    loadMedia().catch(error => {
      console.error("メディアのロード中にエラーが発生しました:", error);
      setItems([]);
    });
  }, []);
  return items;
};

const MediaPresenter = ({ items }) => (
    <section className={classNames(Style.ownedMedia)}>
  {items.length > 0 && (
    <div>
      <h2 className={classNames(Style.catch)}>
        <span>comachicartメディア</span>
      </h2>
      <div className={classNames(Style.gridContainer)}>
          {items.length > 0 && (
            <Link to={`/comachicart/media/?id=${items[0].id}`} className={classNames(Style.gridItemFull)}>
              <div>
                <div
                  style={{
                    display: "grid",
                    width: "100%",
                    gridTemplateColumns: "40% 1fr", // 左に画像(40%)、右にテキスト(残りのスペース)
                    gap: "16px",
                  }}
                >
                  <img style={{ maxWidth: "100%", height: "auto" }} src={items[0].thumbnailImage} alt="ニュース画像" />
                  <div className={classNames(Style.gridFirstContentCard)}>
                    <div>
                      <div className={classNames(Style.categoryContainer)}>
                        {items[0].category.length > 0 && (
                          items[0].category.map(category => (
                            <div
                              style={{
                                backgroundColor: category.color,
                                color: "white",
                                marginRight: "5px",
                                padding: "5px",
                                borderRadius: "5px"
                              }}
                              className={classNames(Style.gridFirstContentCard)}
                              key={category.name}
                            >
                              {category.name}
                            </div>
                          ))
                        )}
                      </div>
                    </div>

                    <div>
                      <p className={classNames(Style.gridFirstTitle)} style={{ fontWeight: "bold" }} href={`./media/?id=${items[0].id}`}>
                        {items[0].title}
                      </p>
                    </div>
                    <div style={{ color: "#5e5e5e" }}>{items[0].publishDate}</div>
                    <div
                      className={classNames(Style.gridFirstContent)}
                      dangerouslySetInnerHTML={{ __html: items[0].content }}
                    ></div>
                  </div>
                </div>
              </div>
            </Link>
          )}

          {items.slice(1).map((item, index) => (
            <Link to={`/comachicart/media/?id=${item.id}`} className={classNames(Style.gridItem)}>
              <div
                key={index}
              >
                {/* タグを追加 */}
                <img className={classNames(Style.gridImg)} src={item.thumbnailImage}></img>
                <div className={classNames(Style.categoryContainer)}>
                  {item.category.length > 0 && (
                    item.category.map(category => (
                      <div
                        style={{
                          backgroundColor: category.color,
                          color: "white",
                          marginBottom: "10px",
                          marginRight: "5px",
                          padding: "2px 5px",
                          borderRadius: "5px",
                          whiteSpace: "normal",
                          wordBreak: "break-all"
                        }}
                        key={category.name}
                      >
                        {category.name}
                      </div>
                    ))
                  )}
                </div>

                <div>
                  <p className={classNames(Style.gridItemTitle)}>{item.title}</p>
                </div>
                <div style={{ color: "#5e5e5e" }}>{item.publishDate}</div>
              </div>
            </Link>
          ))}
        </div>
    </div>
    )}
  </section>
);

const Media = () => {
  const items = useMedia();
  return <MediaPresenter items={items} />;
};

export default Media;
