import {faCalendarAlt, faCalendarCheck, faComments, faEnvelope, faIdCard} from '@fortawesome/free-regular-svg-icons';
import {faAward, faBoxes, faChild, faCoins, faRobot} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Image from 'components/image';
import accountImg from 'images/comachi_lp/top/account.png';
import bagImg from 'images/comachi_lp/top/bag.png';
import creditImg from 'images/comachi_lp/top/creditcard.png';
import photoImg from 'images/comachi_lp/top/photo.png';
import icnComachiLpDevice from 'images/icn_comachi_lp_device.png';
import icnComachiLpSingleBox from 'images/icn_comachi_lp_single_box.png';
import React from 'react';
import Style from 'styles/components/comachicart/feature.module.scss';

const ComachiFeature = () => {
  const imagePrefix = 'comachi_lp/top/';
  const iconStyle = {
    paddingRight: '10px',
    height: '32px',
    width: '32px',
    verticalAlign: 'middle',
  };
  return (
    <div className={classNames(Style.feature)}>
      <section>
        <h2 className={classNames(Style.catch)}>
          <span>comachicart(コマチカート)とは</span>
        </h2>
        <div className={classNames(Style.sub_catch)}>
          <span>
            誰でも「かんたん」に自分だけのお店（ネットショップ）をオープンすることができる
            <br />
            ネットショップ制作サービスです。
          </span>
        </div>
        <div className={Style.origin}>
          <div className={classNames(Style.origin__column, Style.origin__text)}>
            <div className={classNames(Style.origin__text__decoration)}>サービス名の由来は、「 コマース × 町 」 </div>
            <div>
              <div>「私たちの提供する価値（サービス）を通じて、</div>
              <div>コマースが賑やかになり、町が賑やかになり、</div>
              <div>人々の接点・交流が広がることで地域社会の活性化を図りたい」</div>
              <div>
                <br />
                そのような想いを胸に、日々サービスの運営・改善に努めております。
              </div>
            </div>
          </div>
          <Image className={classNames(Style.origin__column)} filename={imagePrefix + "original.png"} />
        </div>
      </section>
      <section>
        <h2 className={classNames(Style.catch)}>
          <span>comachicartの3つの特徴</span>
        </h2>
        <h3 className={classNames(Style.dogear)}>
          <span>① かんたんにネットショップを開設できる</span>
        </h3>
        <div className={Style.feature__cards}>
          <div className={classNames(Style.feature__point)}>ネットショップの導入から、オープンまでがスムーズ</div>
          <ul>
            <li>
              <div>
                <img src={accountImg} />
                <div className={classNames(Style.feature__point)}>1. アカウントを取得</div>
                <div>
                  <span>
                    まずは、「申し込む」ボタンより
                    <br />
                    お申込み下さい。
                  </span>
                </div>
                <a
  className = {classNames(Style.feature__link)} href =
      'https://www.youtube.com/watch?v=a25mOU9cMo8'
                  target='_blank'
                >
                  申し込みの流れを見る
                  <br />
                  （動画）→
                </a>
              </div>
            </li>
            <li>
              <img src={bagImg} />
              <div className={classNames(Style.feature__point)}>2. 販売商品の登録</div>
              <div>
                <span>
                  お客様専用の管理画面から登録を行います。
                  <br />
                  CSVでの一括アップロードも可能です。
                </span>
              </div>
            </li>
            <li>
              <img src={photoImg} />
              <div className={classNames(Style.feature__point)}>3. デザイン編集</div>
              <div>
                <span>
                  テンプレート素材や、デザインパーツ機能で
                  <br />
                  専門知識不要でお好みの見た目に
                  <br />
                  変更できます。
                </span>
              </div>
            </li>
            <li>
              <img src={creditImg} />
              <div className={classNames(Style.feature__point)}>4. 決済導入</div>
              <div>
                <span>代金引換やクレジットカード決済など、</span>
                <span>ご希望の決済手段を設定します。</span>
                <span>
                  （各決済サービス提供会社へのお申し込み
                  <br />
                  が必要となります）
                </span>
              </div>
            </li>
          </ul>
        </div>
        <h3 className={classNames(Style.dogear)}>
          <span>② 専門知識不要で、あなた好みの見た目のショップができる</span>
        </h3>
        <div className={classNames(Style.sub_catch)}>
          <span>
            デザインテンプレートやデザインパーツ機能を使うことで、
            <br />
            直観的な操作だけでデザインをカスタマイズすることができます
          </span>
        </div>
        <div className={Style.feature__cards}>
          <ul>
            <li>
              <Image filename={
    imagePrefix + 'template.png'} />
              <div className={classNames(Style.feature__point, Style.feature__point__decoration)}>
                デザインテンプレート
              </div>
              <div>
                <span>
                  全体のデザインのひな型となる「テンプレート」が用意されていますので、テンプレートを選ぶだけでショップ全体のイメージを作ることができます。
                </span>
              </div>
            </li>
            <li>
              <Image filename={imagePrefix + "parts.png"} />
              <div className={classNames(Style.feature__point, Style.feature__point__decoration)}>デザインパーツ</div>
              <div>
                <span>
                  スライドショーや画像・テキスト・動画など、「パーツを追加・移動するだけ」で、あなた好みのサイトの構成が出来上がります。難しいプログラミングは必要ありません。
                </span>
              </div>
            </li>
            <li>
              <Image filename={
    imagePrefix + 'change.png'} />
              <div className={classNames(Style.feature__point, Style.feature__point__decoration)}>
                背景・フォント変更
              </div>
              <div>
                <span>
                  テンプレートの背景画像を変更したり、テキストのフォントやカラーの変更が行えます。背景画像は、ショップユーザー様で用意された画像にも変更できますので、ショップの世界観を自由に表現することが可能です。
                </span>
              </div>
            </li>
          </ul>
        </div>
        <h3 className={classNames(Style.dogear)}>
          <span>③ 必要な機能が標準で使える</span>
        </h3>
        <div className={classNames(Style.sub_catch)}>
          <span>
            ネットショップにおける様々な販売手法に
            <br />
            対応できる機能をご用意しております。
          </span>
        </div>
        <div className={classNames(Style.sub_catch, Style.sub_catch__decoration)}>
          <span>便利な機能を随時追加していく予定です。</span>
        </div>
        <div className={Style.feature__lists}>
          <ul>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faChild} />
                  会員登録
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faIdCard} />
                  顧客管理
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faCoins} />
                  ポイント
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faCalendarAlt} />
                  定期販売
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faCalendarCheck} />
                  予約販売
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faAward} />
                  限定販売
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faBoxes} />
                  まとめ買い
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <img style={iconStyle} src={
    icnComachiLpSingleBox} />
                  個数制限
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faComments} />
                  レビュー
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faRobot} />
                  自動メール配信
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <FontAwesomeIcon style={iconStyle} icon={
    faEnvelope} />
                  メルマガ
                </span>
              </div>
            </li>
            <li>
              <div>
                <span>
                  <img style={iconStyle} src={
    icnComachiLpDevice} />
                  レスポンシブ対応
                </span>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default ComachiFeature;
