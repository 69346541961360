import classNames from 'classnames';
import React from 'react';
import Style from 'styles/components/comachicart/partnerinformation.module.scss';

const Func = () => {
  return (
    <div className={classNames(Style.zeus)}>
      <h2 className={classNames(Style.catch)}>
          <span>ゼウス社パートナーページ掲載のご紹介</span>
      </h2>
      <div className={Style.origin}>
        <div className={classNames(Style.origin__column, Style.origin__text)}>
          <div>
            <div>この度、ゼウス社の提供するクレジットカード決済システムを導入している</div>
            <div>サービスとして、comachicartがゼウス社のパートナーページに掲載されました。</div>
            <div>
            掲載されたパートナーページは
              <a
                className={classNames(Style.origin__notice__link)}
                href='https://www.cardservice.co.jp/agent/partner_comachicart.html'
                target="_blank"
                rel="noreferrer"
              >
                こちら
              </a>
                からご覧いただけます。
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Func;
